@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this to your stylesheet */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}



@import url('https://fonts.googleapis.com/css2?family=Domine&display=swap');

.domine {
  font-family: 'Domine', serif;
}

@font-face {
  font-family: centurygothic;
  src: url('../src/font/century-gothic.ttf');
}

.centurygothic {
  font-family: centurygothic;
}

  .active-style {
    color: #ffb400 !important;
    font-weight: 400;
}

/* body {
  text-align: center;
  padding: 0 10px;
} */

.container{
  padding: 0 10px;
}

.active {
  color: #ffb400 !important;
  font-weight: 400;
}

.gradienthalf {
  background-image: linear-gradient(0deg, #0f0f0f 85%, #FFFFFF 0%);
}

@media only screen and (min-width: 640px) {
  .gradienthalf {
    background-image: linear-gradient(0deg, #0f0f0f 70%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1024px) {
  .gradienthalf {
    background-image: linear-gradient(90deg, #0f0f0f 70%, #FFFFFF 0%);
  }
}

.revgradienthalf {
  background-image: linear-gradient(0deg, #0f0f0f 75%, #FFFFFF 0%);
}

@media only screen and (min-width: 1024px) {
  .revgradienthalf {
    background-image: linear-gradient(270deg, #0f0f0f 70%, #FFFFFF 0%);
  }
}

.gradientteam {
  background-image: linear-gradient(0deg, #0f0f0f 84%, #FFFFFF 0%);
}

@media only screen and (min-width: 500px) and (max-width: 639px) {
  .gradientteam {
    background-image: linear-gradient(0deg, #0f0f0f 78%, #FFFFFF 0%);
  } 
}

@media only screen and (min-width: 640px) and (max-width: 767px) {
  .gradientteam {
    background-image: linear-gradient(0deg, #0f0f0f 75%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gradientteam {
    background-image: linear-gradient(0deg, #0f0f0f 78%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 84%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 82%, #FFFFFF 0%);
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 80%, #FFFFFF 0%);
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1536px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 80%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1537px) and (max-width: 1700px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 85%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1900px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 80%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1901px) and (max-width: 2200px) {
  .gradientteam {
    background-image: linear-gradient(90deg, #0f0f0f 78%, #FFFFFF 0%);
  }
}



.gradientteamrev {
  background-image: linear-gradient(0deg, #0f0f0f 84%, #FFFFFF 0%);
}

@media only screen and (min-width: 500px) and (max-width: 639px) {
  .gradientteamrev {
    background-image: linear-gradient(0deg, #0f0f0f 78%, #FFFFFF 0%);
  } 
}

@media only screen and (min-width: 640px) and (max-width: 767px) {
  .gradientteamrev {
    background-image: linear-gradient(0deg, #0f0f0f 75%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gradientteamrev {
    background-image: linear-gradient(0deg, #0f0f0f 78%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 84%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 82%, #FFFFFF 0%);
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 80%, #FFFFFF 0%);
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1536px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 80%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1537px) and (max-width: 1700px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 85%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1900px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 80%, #FFFFFF 0%);
  }
}

@media only screen and (min-width: 1901px) and (max-width: 2200px) {
  .gradientteamrev {
    background-image: linear-gradient(270deg, #0f0f0f 78%, #FFFFFF 0%);
  }
}

.transonhov:not(:hover) {
  transform: scale(0.9);
  transition: transform 0.4s ease-in-out;
}
.transonhov:hover {
  transform: scale(1.1);
  transition: transform 0.4s ease-in-out;
}

#industry {
  transition: background-image 1.4s ease-in-out;
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
  .circle {
    width: 250px;
    height: 104px;
  }
  .halfcircle {
    width: 140px;
    height: 104px;
  }
}

@media only screen and (min-width: 851px) and (max-width: 1023px) {
  .circle {
    width: 250px;
    height: 120px;
  }
  .halfcircle {
    width: 140px;
    height: 120px;
  }
}

@media only screen and (min-width: 1024px) {
  .circle {
    width: 250px;
    height: 140px;
  }
  .halfcircle {
    width: 130px;
    height: 140px;
  }
}
/* @media only screen and (min-width: 1200px) {
  .circle {
    width: 250px;
    height: 170px;
  }
  
} */
@media only screen and (min-width: 1200px) {
  .circle {
    width: 250px;
    height: 180px;
  }
  .halfcircle {
    width: 140px;
    height: 180px;
  }
}
@media only screen and (min-width: 1400px) {
  .circle {
    width: 250px;
    height: 200px;
  }
  .halfcircle {
    width: 130px;
    height: 200px;
  }
}
@media only screen and (min-width: 1600px) {
  .circle {
    width: 250px;
    height: 230px;
  }
  .halfcircle {
    width: 130px;
    height: 230px;
  }
}
@media only screen and (min-width: 1800px) {
  .circle {
    width: 250px;
    height: 250px;
  }
  .halfcircle {
    width: 130px;
    height: 250px;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

.dropdowni:hover .dropdown-content-i {
  display: block;
}

@media only screen and (min-width: 1024px) and (max-width: 1034px) {
  .adjlogo {
    width: 75%;
    margin-right: 0;
  }
}